import { Routes, Route } from 'react-router-dom'
import LoginView from '../pages/LoginView'
import RegisterPage from '../pages/RegisterPage'
import Memberships from '../pages/Memberships'
// import Loader from "../appStates/LoadingView";

function NotSignedRoutes() {
    return (
        <Routes>
            <Route path="/subscription" element={<Memberships />} />
            <Route path="/memberships" element={<Memberships />} />

            <Route path="/oauth/:tab" element={<LoginView />} />
            <Route path="/oauth" element={<LoginView />} />
            <Route path="/oauth/finishSetup" element={<RegisterPage />} />
            {/* <Route path="/login" element={<div onClick={async () => {
                await signInWithEmailAndPassword(auth, "jahziel.cabrera+test5@gigstack.io", "12345678")
            }}><LoginView /></div>} /> */}
            <Route path="/login" element={<LoginView />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/signup" element={<RegisterPage />} />
            <Route path="*" element={<LoginView />} />
        </Routes>
    )
}

export default NotSignedRoutes
