import { useDispatch, useSelector } from 'react-redux'
import { PaymentComplementModal, PaymentComplementRelationsModal } from '../components/invoices/PaymentComplement'
import AssignClientModal from '../modals/AssignClientModal'
import CancelInvoiceModal from '../modals/CancelInvoiceModal'
import CreateAddTaxModal from '../modals/CreateAddTaxModal'
import CreateClientModal from '../modals/CreateClientModal'
import CreateInvoiceFromReceiptsModal from '../modals/CreateInvoiceFromReceiptModal'
import CreateNewTemplate from '../modals/CreateNewTemplate'
import CreateServiceModal from '../modals/CreateServiceModal'

import CreateTeamModal from '../modals/CreateTeamModal'
import IntegrationModal from '../modals/IntegrationModal'
import PaymentConcilliationModal from '../modals/PaymentConcilliationModal'
import SendElementByEmail from '../modals/SendElementByEmail'
import ServiceSeleccionModal from '../modals/ServiceSeleccionModal'
import StampInvoiceModal from '../modals/StampInvoiceModal'
import SubstituteInvoiceModal from '../modals/SubstituteInvoiceModal'
import TeamSelectionModal from '../modals/TeamSelectionModal'
import { ViewAllAlgolia } from '../search/ViewAllComponente'
import CreateClientDrawer from './CreateClientDrawer'
// import { CreateInvoice } from "./CreateInvoiceDrawer";
import { CreatePaymentDrawer } from './CreatePaymentDrawer'
import { CreateReceipt } from './CreateReceiptDrawer'

import { TemplateEmailsDrawer } from './TemplatesEmailsDrawe'
import SettingsDrawer from './SettingsDrawer'
import ApprovePayment from '../modals/ApprovePayment'
import { RegisterPaymentDrawer } from './RegisterPaymentDrawer'
import { RelationsDrawer } from '../components/Relations/RelationsDrawerView'
import { StripeModal } from '../modals/integrations/StripeModal'
import { SatModal } from '../modals/integrations/SatModal'
import { OpenpayModal } from '../modals/integrations/OpenpayModal'
import { AirtableModal } from '../modals/integrations/AirtableModal'
import { WhmcsModal } from '../modals/integrations/WhmcsModal'
import { HilosModal } from '../modals/integrations/HilosModal'
import { SheetsModal } from '../modals/integrations/SheetsModal'
import { SpeiModal } from '../modals/integrations/SpeiModal'
import { ZapierModal } from '../modals/integrations/ZapierModal'
import { DownloadsModal } from '../modals/DownloadFilesModal'
import { AutoInvoiceModal } from '../modals/AutoInvoiceProcessModal'
import { MergeClientsModal } from '../modals/MergeClientsModal'
import AddRelatedPayment from '../modals/RelationPayment'
import AddRelatedInvoice from '../modals/RelationInvoices'
import CannyModalWidget from '../modals/CannyModalWidget'
import ClientDefaultsModal from '../modals/ClientDefaultsModal'
import { UploadThingsModal } from '../modals/UploadThingsModal'
import CancelResourcesModal from '../modals/CancelResourcesModal'
import { CreateInvoiceV2 } from './CreateInvoiceDrawerV2'
import StripeBankInstructionsModal from '../modals/DisplayStripeBankInstructions'
import { EditWebhooksModal } from '../modals/EditWebhooksModal'
import { WoocomerceModal } from '../modals/integrations/WoocomerceModal'
import { PayPalModal } from '../modals/integrations/PayPalModal'
import { CreateInvoiceMassively } from './CreateInvoiceMassively'
import { ConfirmMassivePayInvoiceModal } from '../modals/ConfirmMassivePayInvoiceModal'
import { MassiveInvoiceStatusDrawer } from './MassiveInvoiceStatusDrawer'
import { EditFailedMassInvoice } from '../modals/EditFailedMassInvoice'
import { useEffect } from 'react'
import { cleanInvoicesContext } from './helpers'
import { ClipModal } from '../modals/integrations/ClipModal'

const ElementHolder = () => {
    const { team } = useSelector((state: any) => state.team)
    const {
        serviceSelectionVisible,
        receiptModalVisible,
        paymentComplementModalVisible,
        paymentComplementRelationsModalVisible,
        mergeClientsModalVisible,
        registerPaymentModalVisible,
        relatedPaymentModalVisible,
        relatedInvoicesModalVisible,
        stampInvoiceModalVisible,
        invoiceModalVisible,
        cancelInvoiceModalVisible,
        substituteInvoiceModalVisible,
        relationsDrawerVisible,
        createNewTemplateEmailModalVisible,
        addTaxModalVisible,
        paymentModalVisible,
        sendByEmailModalVisible,
        cancelResourcesModalVisible,
        editWebhooksModalVisible,
        confirmMassivePayInvoiceModalVisible,
        massiveInvoiceStatusDrawerVisible,
        editFailedMassInvoiceVisible
    } = useSelector((state: any) => state.modals)

    const dispatch = useDispatch()

    const { createServiceVisible } = useSelector((state: any) => state.modals)
    const { payment } = useSelector((state: any) => state.data)
    const { client } = useSelector((state: any) => state.data)

    useEffect(() => {
        if(!invoiceModalVisible) {
            cleanInvoicesContext({ dispatch })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceModalVisible])

    return (
        <>
            {team?.id && <TemplateEmailsDrawer />}
            {sendByEmailModalVisible && <SendElementByEmail />}
            {relationsDrawerVisible && <RelationsDrawer />}
            {createNewTemplateEmailModalVisible && <CreateNewTemplate />}
            {(payment?.fid ?? payment?.id) && payment?.status === 'review_requested' && <ApprovePayment />}
            {((payment?.fid ?? payment?.id) || client?.id) && <StripeBankInstructionsModal />}
            {addTaxModalVisible && <CreateAddTaxModal />}
            {paymentModalVisible && <CreatePaymentDrawer />}
            {serviceSelectionVisible && <ServiceSeleccionModal />}
            {(serviceSelectionVisible || createServiceVisible) && <CreateServiceModal />}
            {stampInvoiceModalVisible && <StampInvoiceModal />}
            {substituteInvoiceModalVisible && <SubstituteInvoiceModal />}
            {cancelInvoiceModalVisible && <CancelInvoiceModal />}
            <IntegrationModal />
            <TeamSelectionModal />
            <CreateTeamModal />
            {registerPaymentModalVisible && <RegisterPaymentDrawer />}
            {/* {invoiceModalVisible && <CreateInvoice />} */}
            {invoiceModalVisible && <CreateInvoiceV2 />}
            {relatedPaymentModalVisible && <AddRelatedPayment />}
            {relatedInvoicesModalVisible && <AddRelatedInvoice />}
            {cancelResourcesModalVisible && <CancelResourcesModal />}
            <ViewAllAlgolia />
            <CreateInvoiceFromReceiptsModal />
            {receiptModalVisible && <CreateReceipt />}
            {editWebhooksModalVisible && <EditWebhooksModal />}
            {confirmMassivePayInvoiceModalVisible && <ConfirmMassivePayInvoiceModal />}
            {massiveInvoiceStatusDrawerVisible && <MassiveInvoiceStatusDrawer />}
            {editFailedMassInvoiceVisible && <EditFailedMassInvoice />}

            <CreateClientModal />
            {client && <ClientDefaultsModal />}
            <PaymentConcilliationModal />

            <AssignClientModal />

            <SettingsDrawer />

            <StripeModal />
            <SatModal />
            <SpeiModal />
            <OpenpayModal />
            <AirtableModal />
            <WoocomerceModal />
            <PayPalModal />
            <WhmcsModal />
            <HilosModal />
            <SheetsModal />
            <ZapierModal />
            <ClipModal />

            <DownloadsModal />

            <AutoInvoiceModal />
            {mergeClientsModalVisible && <MergeClientsModal />}
            {paymentComplementModalVisible && <PaymentComplementModal />}
            {paymentComplementRelationsModalVisible && <PaymentComplementRelationsModal />}
            <CreateClientDrawer />
            <CannyModalWidget />
            <UploadThingsModal />
            <CreateInvoiceMassively />
        </>
    )
}

export default ElementHolder
