import React, { useState } from 'react'
import { Row, Col, Typography, Form, Button, message, Divider } from 'antd'
import { useNavigate } from 'react-router-dom'
import { CheckBoxInput, PasswordInput, TextInput } from '../components/Forms/Inputs'
import { EnvelopeOpen, Keyhole } from '@phosphor-icons/react'
import { GoogleAuthProvider, createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { logEvent } from 'firebase/analytics'
import { useAnalytics, useAuth } from 'reactfire'
import { ReadableFirebaseErrorMessage, SearchParamInURL } from '../functions/helpers'
import google from '../../assets/images/google.png'
import ErrorsInFormComponent from '../components/Forms/ErrorsInFormComponent'
import { usePostHog } from 'posthog-js/react'

const RegisterPage: React.FC = () => {
    const navigate = useNavigate()
    const posthog = usePostHog()
    const [loading, setLoading] = useState('')
    const analytics = useAnalytics()
    const auth = useAuth()
    let redirect = SearchParamInURL('redirect')

    const [messageApi, contextHolder] = message.useMessage()
    const [errors, setErrors] = useState([])

    const [form] = Form.useForm()

    const MakeGoogleSignOn = async () => {
        setLoading('google')
        try {
            posthog?.capture('signed_up_with_google')
        } catch (error) {}
        setTimeout(() => {
            const provider = new GoogleAuthProvider()

            provider.addScope('profile')
            provider.addScope('email')
            logEvent(analytics, 'auth', { type: 'google' })
            signInWithPopup(auth, provider)
                .then(async (result) => {
                    const user = result.user

                    if (redirect) {
                        window.location.replace(redirect)
                    }
                    return user
                })
                .catch((error) => {
                    setLoading('')
                    return messageApi.error(ReadableFirebaseErrorMessage(error))
                })
        }, 300)
    }

    const RegisterWithEmail = async (v: any) => {
        const { email, password, repeatPassword } = v
        try {
            await form.validateFields()
        } catch (error: any) {
            setErrors(error.errorFields)
            return
        }
        if (password !== repeatPassword) {
            return messageApi.error('Las contraseñas no coinciden')
        }
        setLoading('email')
        try {
            posthog?.capture('signed_up_with_email')
        } catch (error) {}
        try {
            await createUserWithEmailAndPassword(auth, email, password)
            setLoading('')
            if (redirect) {
                let correctRed = redirect
                if (SearchParamInURL('code')) {
                    correctRed = correctRed + `?code=${SearchParamInURL('code')}`
                }
                if (SearchParamInURL('customPriceId')) {
                    correctRed = correctRed + `?customPriceId=${SearchParamInURL('customPriceId')}`
                }
                navigate(correctRed)
                // navigate(redirect)
            } else {
                const route = window.location.pathname.split('/')[1]
                if (route !== 'oauth') return navigate('/')
            }
        } catch (error) {
            setLoading('')
            return messageApi.error(ReadableFirebaseErrorMessage(error), 5)
        }
    }

    return (
        <div
            style={{
                position: 'relative',
                minHeight: '100vh',
                background: 'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
            }}
        >
            {contextHolder}
            <Row justify="center">
                <Col
                    xs={23}
                    lg={14}
                    xl={10}
                    xxl={9}
                    style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '10px',
                        marginTop: '10px',
                        marginBottom: '20px',
                    }}
                >
                    <Row justify="space-between">
                        <Typography.Text className="russoOne primary">gigstack pro</Typography.Text>
                        <Typography.Text className="smallparagraph">
                            ¿Ya tienes una cuenta?{' '}
                            <span
                                className="smallparagraphbold clickable"
                                onClick={() => {
                                    let redirect = SearchParamInURL('redirect')
                                    if (redirect) {
                                        if (SearchParamInURL('code')) {
                                            redirect = redirect + `&code=${SearchParamInURL('code')}`
                                        }
                                        if (SearchParamInURL('customPriceId')) {
                                            redirect = redirect + `&customPriceId=${SearchParamInURL('customPriceId')}`
                                        }
                                    }

                                    navigate(`/login${redirect ? '?redirect=' + redirect : ''}`)
                                }}
                                style={{ marginLeft: '5px' }}
                            >
                                Inicia Sesión
                            </span>
                        </Typography.Text>
                    </Row>

                    <Row style={{ marginTop: '25px' }}>
                        <Typography.Text className="bigparagraphbold" style={{ margin: 0, padding: 0 }}>
                            Crea una cuenta
                        </Typography.Text>
                    </Row>

                    <Row style={{ marginTop: '5px' }}>
                        <Typography.Text className="smallparagraph descriptions">
                            La mejor plataforma de revenue management para tu empresa, crea una cuenta y comienza a
                            disfrutar de todos los beneficios que tenemos para ti.
                        </Typography.Text>
                    </Row>

                    <Button
                        className="inter"
                        loading={loading === 'google'}
                        disabled={loading === 'email'}
                        style={{
                            borderColor: window.innerWidth < 801 ? `white` : '#D8DAE5',
                            display: 'flex',
                            width: '100%',
                            padding: '8px 16px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: 'auto',
                            marginTop: '25px',
                        }}
                        onClick={MakeGoogleSignOn}
                    >
                        <img
                            src={google}
                            alt="Google Logo"
                            style={{
                                width: '14px',
                                height: '14px',
                                marginRight: '8px',
                            }}
                        />
                        {`Regístrate con Google`}
                    </Button>
                    <Divider>
                        <Typography.Text className="smallparagraph descriptions">continúa con email</Typography.Text>
                    </Divider>
                    <Form
                        onFinish={RegisterWithEmail}
                        onFinishFailed={(error: any) => {
                            setErrors(error.errorFields)
                        }}
                        form={form}
                        style={{
                            marginTop: '25px',
                        }}
                        layout="vertical"
                    >
                        <TextInput
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingresa tu correo',
                                },
                                {
                                    type: 'email',
                                    message: 'Ingresa un correo válido',
                                },
                            ]}
                            prefix={<EnvelopeOpen size={19} className="icon" />}
                            name="email"
                            placeholder="Correo electrónico"
                            label=""
                        />
                        <PasswordInput
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingresa tu contraseña',
                                },
                                {
                                    min: 8,
                                    message: 'La contraseña debe tener al menos 8 caracteres',
                                },
                            ]}
                            prefix={<Keyhole size={19} className="icon" />}
                            name="password"
                            placeholder="Contraseña"
                            label=""
                        />
                        <PasswordInput
                            rules={[
                                {
                                    required: true,
                                    message: 'Repite tu contraseña',
                                },
                            ]}
                            prefix={<Keyhole size={19} className="icon" />}
                            name="repeatPassword"
                            placeholder="Repetir Contraseña"
                            label=""
                        />
                        <CheckBoxInput
                            rules={[
                                {
                                    required: true,
                                    message: 'Debes aceptar los términos y condiciones',
                                },

                                {
                                    validator: (_: any, value: boolean) =>
                                        value
                                            ? Promise.resolve()
                                            : Promise.reject(new Error('Por favor acepta los términos y condiciones')),
                                },
                            ]}
                            name="terms"
                            label=""
                            content={
                                <a
                                    href="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/terms.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Typography.Text className="smallparagraph">
                                        Acepto los términos y condiciones
                                    </Typography.Text>
                                </a>
                            }
                        />
                        <ErrorsInFormComponent
                            formErrors={errors}
                            form={form}
                            title={'Completa los campos necesarios'}
                        />
                        <Button
                            loading={loading === 'email'}
                            disabled={loading === 'google'}
                            type="primary"
                            htmlType="submit"
                            style={{ width: '100%' }}
                        >
                            Registrarme
                        </Button>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default RegisterPage
