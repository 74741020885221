import React from 'react'
import { Card, Typography, Button, Space, message, Divider } from 'antd'
import { CheckCircle, XCircle } from '@phosphor-icons/react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFrontUrl, isStagingOrLocal } from '../Integrations/stripeHelpers'
import { useSelector } from 'react-redux'
import { PricingPlan } from '../../../interfaces/PricingPlanDef'
import { returnCurrencyValue, SearchParamInURL } from '../../functions/helpers'
import { useNavigate, useSearchParams } from 'react-router-dom'

const featureTranslations: { [key: string]: string } = {
    includedDocuments: 'eventos incluidos',
    seats: 'Asiento',
    maxClients: 'Clientes',
    selfBillingPortal: 'Portal de facturación propia',
    maxIntegrations: 'Integraciones',
    customerPortal: 'Portal de clientes',
    technicalSupport: 'Soporte técnico',
    apiAndWebhooks: 'API y Webhooks',
    customDomain: 'Dominio personalizado',
    multipleIssuerAccounts: 'Múltiples cuentas de emisor',
    analyticsAndReports: 'Análisis e informes',
    dedicatedSupportChannel: 'Canal de soporte dedicado',
    supplierMode: 'Modo proveedor',
}

interface PricingCardProps {
    plan: PricingPlan
    isRecommended?: boolean
    disabled?: boolean
    onSelect?: () => void
}

const featureOrder = [
    'includedDocuments',
    'seats',
    'maxClients',
    'selfBillingPortal',
    'maxIntegrations',
    'customerPortal',
    'technicalSupport',
    'apiAndWebhooks',
    'customDomain',
    'multipleIssuerAccounts',
    'analyticsAndReports',
    'dedicatedSupportChannel',
    'supplierMode',
]

const PricingCard = ({ plan, isRecommended = false, onSelect, disabled }: PricingCardProps) => {
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const formatFeatureValue = (featName: string, value: number | string | boolean | undefined): string => {
        if (value === undefined) return 'No disponible'
        if (typeof value === 'boolean') return featureTranslations[featName]
        if (value === 'unlimited') return `${featureTranslations[featName]} ilimitados`
        if (featName === 'includedDocuments') return `${value} ${featureTranslations[featName]}`
        return `${value} ${featureTranslations[featName]}`
    }

    const checkoutSubscription = async () => {
        const requestId = SearchParamInURL('request_id')
        const state = SearchParamInURL('state')

        let successUrl = `${getFrontUrl()}/gettingStarted`

        if (requestId) {
            successUrl = `${getFrontUrl()}/oauth?request_id=${requestId}`
            if (state) {
                successUrl += `&state=${state}`
            }
        }

        const billingManager = httpsCallable(getFunctions(), 'internalbillingapp')
        const checkout = await billingManager({
            successUrl,
            action: 'subscribe',
            plan: plan.id,
            quantity: 1,
            isStaging: isStagingOrLocal(),
        })

        if (plan.id === 'free') return

        const data = checkout.data as any
        if (data.session) {
            window.open(data.session?.url, '_self')
        } else if (data.raw?.message) {
            message.error(data.raw?.message)
        }
    }

    const sortedFeatures = featureOrder.map((featName) => ({
        name: featName,
        value: plan.features[featName as keyof typeof plan.features],
    }))

    const isCurrentPlan = billingAccount?.plan?.id === plan.id

    const getButtonText = () => {
        if (isCurrentPlan) return 'Plan actual'
        if (plan.id === 'free') return 'Continuar'
        if (plan.id === 'free-plus') return 'Continuar con tarjeta'
        return 'Seleccionar'
    }

    const getButtonClass = () => {
        if (isCurrentPlan) return 'btn-outline'
        if (isRecommended) return 'btn-pricing-plans'
        if (disabled) return 'btn-secondary'
        if (plan.id === 'free') return 'btn-primary'
        if (plan.id === 'free-plus') return 'btn-primary'
        return 'btn-primary'
    }

    const handleButtonClick = async () => {
        if (!billingAccount?.id) {
            const customPriceId = searchParams.get('customPriceId')
            const code = searchParams.get('code')
            let red = '/signup?redirect=/memberships'
            if (customPriceId) {
                red = `${red}&customPriceId=${customPriceId}`
            }
            if (code) {
                red = `${red}&code=${code}`
            }
            return navigate(red)
        }

        if (plan.id === 'free' && onSelect) {
            checkoutSubscription()
            onSelect()
        } else {
            checkoutSubscription()
        }
    }

    return (
        <Card
            className="pricing-card shadow-lg rounded-lg"
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
            <Space
                direction="vertical"
                size="large"
                style={{ width: '100%', height: '100%', justifyContent: 'space-between' }}
            >
                <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <Typography.Title level={3} style={{ margin: 0 }}>
                        {plan.name}
                    </Typography.Title>
                    <Space align="baseline" style={{ marginBottom: 16 }}>
                        <Typography.Title level={2} style={{ margin: 0 }}>
                            {returnCurrencyValue(plan?.price.baseAmount! / 100)}
                        </Typography.Title>
                        <Typography.Text type="secondary">
                            {plan.price.currency} {plan.price.billingPeriod === 'monthly' ? '/mes' : '/año'}
                        </Typography.Text>
                    </Space>
                    <Divider style={{ margin: '12px 0' }} />
                    <Space direction="vertical" style={{ width: '100%' }}>
                        {sortedFeatures.map(({ name, value }) => (
                            <Space key={name} align="start">
                                {value ? (
                                    <CheckCircle size={20} weight="bold" style={{ color: 'var(--neutral-1)' }} />
                                ) : (
                                    <XCircle size={20} weight="bold" style={{ color: 'var(--neutral-3)' }} />
                                )}
                                <Typography.Text className={value ? 'neutral-1' : 'neutral-3'}>
                                    {formatFeatureValue(name, value)}
                                </Typography.Text>
                            </Space>
                        ))}
                    </Space>
                </Space>
                <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <Typography.Text type="secondary" style={{ fontSize: '0.9em' }}>
                        Eventos adicionales:{' '}
                        {plan.price.additionalDocumentPrice
                            ? `${returnCurrencyValue(plan.price.additionalDocumentPrice / 100)} ${plan.price.currency}`
                            : 'No Aplica'}
                    </Typography.Text>
                    <Button
                        className={getButtonClass()}
                        block
                        size="large"
                        onClick={handleButtonClick}
                        disabled={isCurrentPlan || disabled}
                    >
                        {getButtonText()}
                    </Button>
                </Space>
            </Space>
        </Card>
    )
}

export default PricingCard
