import { ThemeConfig } from 'antd'

export const antdTheme: ThemeConfig = {
    token: {
        fontFamily: 'Inter',
        colorPrimary: 'var(--neutral-1)',
        borderRadius: 4,
    },
    components: {
        Typography: {
            colorText: 'var(--neutral-1)',
        },
        Input: {
            controlHeight: 40,
            colorTextLabel: '#474D66',
            hoverBorderColor: 'var(--neutral-1)',
            activeBorderColor: 'var(--neutral-1)',
            activeShadow: '0 0 0 0px var(--neutral-1)',
        },
        Layout: {
            colorBgContainer: 'white',
            headerBg: 'white',
            bodyBg: 'white',
            colorBgLayout: 'white',
        },
        Button: {
            colorPrimary: 'var(--neutral-1)',
            colorPrimaryHover: 'var(--neutral-1)',
            fontFamily: 'Inter',
            fontFamilyCode: 'Inter',
        },
        Select: {
            controlHeight: 40,
            optionHeight: 40,
            optionSelectedBg: 'var(--neutral-5)',
            optionSelectedColor: 'var(--neutral-1)',
        },
        Switch: {
            controlHeight: 40,
            trackHeightSM: 20,
            trackMinWidthSM: 36,
        },
        Message: {
            zIndexPopup: 100000,
        },
        Upload: {
            actionsColor: 'var(--neutral-3)',
        },
        DatePicker: {
            cellActiveWithRangeBg: 'var(--neutral-5)',
            activeBorderColor: 'var(--neutral-1)',
            activeShadow: '0 0 0 0px var(--neutral-1)',
            borderRadius: 8,
            controlHeight: 40,
        },
        Table: {
            rowHoverBg: 'var(--neutral-5)',
            rowExpandedBg: 'var(--neutral-5)',
            rowSelectedBg: 'var(--neutral-5)',
            rowSelectedHoverBg: 'var(--neutral-5)',
            borderColor: 'var(--neutral-4)',
            headerBg: 'var(--white)',
            headerColor: 'var(--neutral-3)',
            colorBorder: 'var(--neutral-4)',
        },
        Menu: {
            itemHoverBg: 'var(--neutral-5)',
            itemSelectedBg: 'var(--neutral-5)',
            itemSelectedColor: 'var(--neutral-1)',
            borderRadius: 8,
        },
        Tabs: {
            cardBg: 'var(--neutral-5)',
        },
        Popover: {
            controlHeight: 40,
        },
        Slider: {
            trackBg: 'var(--neutral-1)',
            railBg: 'var(--neutral-4)',
        },
    },
}
