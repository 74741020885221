import { Dropdown, Modal, Popover, Progress, Switch, Tooltip, Typography } from 'antd'
import { ReactElement } from 'react'
import { PongSpinner } from 'react-spinners-kit'
import { useDispatch, useSelector } from 'react-redux'

import { Question, User, MagicWand, LineVertical } from '@phosphor-icons/react'
import { signOut } from 'firebase/auth'
import { useAuth } from 'reactfire'
import type { MenuProps } from 'antd'
import ComponentSearchAlgolia from '../../search/SearchComponent'
import { setData } from '../../context/dataSlice'
import { setTeam } from '../../context/teamsSlice'
import { closeModal, openModal } from '../../context/modalsSlice'
import Feedback from '../Feedback/Feedback'
import { usePostHog } from 'posthog-js/react'
import { teamCompleteGettingStarted } from '../../../helpers/helperFunctions'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'

const EventsComponent = ({ customComponent }: { customComponent?: ReactElement }) => {
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { team } = useSelector((state: any) => state.team)

    let loadingCom = <></>
    if (!billingAccount?.id) {
        loadingCom = <PongSpinner size={20} color={team?.brand?.primaryColor ?? '#515669'} />
    } else if (billingAccount?.plan?.pricingVersion === 'v2') {
        const usedDocuments = billingAccount?.plan?.usedDocuments || 0
        const totalDocuments = billingAccount.plan.features.includedDocuments || 1
        const percentage = Math.min((usedDocuments / totalDocuments) * 100, 100)

        loadingCom = (
            <div style={{ display: 'flex', alignItems: 'center', gap: '9px' }}>
                <Progress
                    type="circle"
                    percent={percentage}
                    size={25}
                    showInfo={false}
                    strokeColor={percentage === 100 ? 'var(--red-6)' : 'var(--neutral-1)'}
                    status={percentage === 100 ? 'exception' : 'active'}
                />
                <div>
                    <Typography className="p-base-regular neutral-3">
                        {usedDocuments}/{totalDocuments}
                    </Typography>
                </div>
            </div>
        )
    } else {
        loadingCom = customComponent ?? (
            <div
                className="d-flex flex-row"
                style={{
                    height: window.innerWidth < 768 ? '30px' : '40px',
                    alignItems: 'center',
                }}
            >
                {window.innerWidth < 768 ? (
                    <Tooltip title={`${billingAccount.credits} créditos`}>{billingAccount?.credits}</Tooltip>
                ) : (
                    <Typography.Text className="p-small-bold neutral-1">
                        {billingAccount?.credits} créditos
                    </Typography.Text>
                )}
            </div>
        )
    }

    let NoCredits = (
        <div className="d-flex flex-column">
            <Typography.Text className="p-small-bold neutral-1">¡Ups!</Typography.Text>
            <Typography.Text className="p-small-regular neutral-1">
                Tu cuenta no tiene créditos disponibles
            </Typography.Text>
        </div>
    )

    if (billingAccount?.payAsYouGo) {
        NoCredits = (
            <div className="d-flex flex-column">
                <Typography.Text className="p-small-bold neutral-1">¡PayAsYouGo!</Typography.Text>
                <Typography.Text className="p-small-regular neutral-1">
                    Realizaremos el cobro de cada evento automatizado extra.
                </Typography.Text>
            </div>
        )
    }

    if (billingAccount?.plan?.pricingVersion === 'v2') {
        NoCredits = (
            <div className="d-flex flex-column">
                <Typography.Text className="p-small-bold neutral-1">¡Límite alcanzado!</Typography.Text>
                <Typography.Text className="p-small-regular neutral-1">
                    Has alcanzado el límite de documentos incluidos en tu plan.
                </Typography.Text>
            </div>
        )
    }

    return <Popover content={billingAccount?.credits < 0 ? NoCredits : null}>{loadingCom}</Popover>
}

function Header() {
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const { testmode, search } = useSelector((state: any) => state.data)
    const { showFeedback } = useSelector((state: any) => state.modals)

    const posthog = usePostHog()
    const gettingStartedFinish = teamCompleteGettingStarted(team)

    const dispatch = useDispatch()

    const auth = useAuth()

    const NeedHelp = () => {
        return (
            <div
                className="d-flex flex-row align-items-center"
                onClick={() => {
                    window.open('https://helpcenter.gigstack.pro/es', '_blank')
                }}
                style={{
                    marginRight: '15px',
                }}
            >
                <Question
                    weight="regular"
                    style={{
                        marginRight: '2px',
                        fontSize: '15px',
                    }}
                    className="icon"
                />
                <Typography.Text className="smallparagraph header-help--text">Ayuda</Typography.Text>
            </div>
        )
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <Typography.Text>Mi perfil</Typography.Text>,
        },
        {
            key: '2',
            label: (
                <Typography.Text
                    onClick={async () => {
                        try {
                            const sessionId = localStorage.getItem('gigstackSessionId')

                            if (sessionId === user?.uniqueSessionId) {
                                localStorage.removeItem('gigstackSessionId')
                                await updateDoc(doc(getFirestore(), `users/${auth.currentUser?.uid}`), {
                                    loggedIn: false,
                                    uniqueSessionId: null,
                                })
                            }
                        } catch (error) {
                            console.log('🚀 ~ onClick={ ~ error:', error)
                        }
                        // return
                        await signOut(auth)
                        // navigate('/login')
                        dispatch(
                            setData({
                                item: 'services',
                                data: [],
                            }),
                        )
                        dispatch(
                            setData({
                                item: 'client',
                                data: [],
                            }),
                        )
                        dispatch(
                            setData({
                                item: 'clients',
                                data: [],
                            }),
                        )
                        dispatch(
                            setData({
                                item: 'taxes',
                                data: [],
                            }),
                        )
                        dispatch(setTeam(null))
                    }}
                >
                    Cerrar sesión
                </Typography.Text>
            ),
        },
    ]

    return (
        <div
            className="d-flex flex-row align-items-center"
            style={{
                justifyContent: 'end',
                width: '100%',
                height: '100%',
                padding: '0px 15px',
            }}
        >
            <div className="d-flex flex-row align-items-center justify-content-space-between" style={{ width: '100%' }}>
                <div style={{ width: '75%' }}>
                    <ComponentSearchAlgolia
                        indexName={search?.type || 'invoices'}
                        teamId={team?.id}
                        type={search?.type || 'invoices'}
                    />
                </div>

                <div className="d-flex flex-row align-items-center justify-content-end header-icons--container">
                    <Switch
                        onChange={(e) =>
                            dispatch(
                                setData({
                                    item: 'testmode',
                                    data: e,
                                }),
                            )
                        }
                        defaultChecked={testmode}
                        checked={testmode}
                        disabled={!gettingStartedFinish}
                        style={{ marginRight: '5px', minWidth: '36px' }}
                        size="small"
                    />

                    {testmode && <Typography className="p-xsmall-regular neutral-3">Modo prueba</Typography>}

                    {team?.id && (
                        <>
                            <LineVertical className="neutral-4" size={17} />
                            <EventsComponent />
                        </>
                    )}

                    <LineVertical className="neutral-4" size={17} />
                    <NeedHelp />

                    {showFeedback && (
                        <Modal
                            width={'90%'}
                            style={{
                                top: 5,
                                width: '90%',
                            }}
                            title="Feedback"
                            open={showFeedback}
                            onCancel={() => dispatch(closeModal('showFeedback'))}
                            footer={null}
                        >
                            <Feedback />
                        </Modal>
                    )}
                    <MagicWand
                        onClick={() => {
                            // window.open('https://gigstack.canny.io/changelog', '_blank')
                            dispatch(openModal('showFeedback'))

                            posthog.capture('feedback_opened', {
                                from: 'header',
                            })
                        }}
                        weight="regular"
                        className="icon clickable"
                        style={{
                            color: '#515669',
                            fontSize: '16px',
                            marginRight: '15px',
                        }}
                    />

                    <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                        <User
                            weight="regular"
                            className="icon clickable"
                            style={{
                                color: '#515669',
                                fontSize: '16px',
                            }}
                        />
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}

export default Header
