import { Button, Row, Space, Tag, Tooltip, Typography } from 'antd'
import { OptimizedPaymentDef, Payment } from '../../../../interfaces/paymentsDef'
import { PaymentStatusTag } from '../../UI/ElementStatus'
import { Pencil, Robot, ShootingStar, UserPlus } from '@phosphor-icons/react'
import PaymentsActions from '../../ElementActions/PaymentsActions'
import { getItemsAmounts, readableElementSource, returnCurrencyValue } from '../../../functions/helpers'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { openModal } from '../../../context/modalsSlice'
import { setData } from '../../../context/dataSlice'
import RelationInvoice from '../../Relations/RelationInvoiceWithPayments'
import { colors } from '../../../designSystem/colors'
import { ElementFromTag } from '../../UI/ElementFrom'
import { CopyableElementId } from '../../UI/CopyableElementId'
import { CheckIfRelating } from '../../Relations/RelatingElementsSignal'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'

type TypePaymentsColumnName =
    | 'id'
    | 'amount'
    | 'clientInfo'
    | 'clientEmail'
    | 'rfc'
    | 'status'
    | 'timestamp'
    | 'succeededTimestamp'
    | 'relations'
    | 'actions'
    | 'automations'
    | 'from'
    | 'suggestedActions'
    | any

export const PaymentsColumns = ({
    record,
    columnName,
    navigate,
    route = '',
}: {
    record: Payment | OptimizedPaymentDef
    columnName: TypePaymentsColumnName
    navigate?: Function
    route?: string
}) => {
    const dispatch = useDispatch()

    switch (columnName) {
        case 'id':
            return <span>{record?.id ?? record?.fid}</span>
        case 'amount':
            // let showTotal = !record.amount && !record?.items ? 0 : getItemsAmounts(record?.items).total
            return (
                <Row justify="end">
                    <Typography.Text onClick={() => {}} className="p-base-bold">
                        {returnCurrencyValue(record.amount / 100)} {(record?.currency ?? '').toUpperCase()}
                    </Typography.Text>
                    {/* <Typography.Text className="smallparagraph descriptions">{returnCurrencyValue(record.amount / 100 ?? 0)} {record.currency.toLocaleUpperCase()}</Typography.Text> */}
                </Row>
            )
        case 'clientInfo':
            const showAddClient = !record.clientId
            const name =
                record?.client?.legal_name ||
                record?.client?.legalName ||
                record?.client?.name ||
                record?.client?.company ||
                record?.client?.email ||
                'Sin nombre'
            // const nameTruncated = (name ?? '')?.length > 20 ? name?.substring(0, 20) + '...' : name
            return (
                <Space direction="vertical" size={0}>
                    <Row>
                        <Typography.Text
                            className="p-base-regular"
                            onClick={
                                !navigate
                                    ? () => {
                                          // TODO: fix this :(
                                          const protocol = window.location.protocol
                                          const host = window.location.host
                                          window.location.href = `${protocol + '//' + host + route}`
                                      }
                                    : () => navigate()
                            }
                        >
                            {name.toUpperCase()}
                        </Typography.Text>
                        {showAddClient && (
                            <Tooltip title="Asignar cliente">
                                <UserPlus size={18} style={{ marginLeft: '10px' }} className="icon clickable" />
                            </Tooltip>
                        )}
                    </Row>
                    <Typography.Text ellipsis className="smallparagraph descriptions" style={{ fontSize: '10px' }}>
                        {record?.client?.name}
                    </Typography.Text>
                    <Typography.Text
                        ellipsis
                        className="smallparagraph descriptions"
                        style={{ fontSize: '10px' }}
                        editable={{
                            icon: <Pencil size={10} />,
                            onChange: async (e) => {
                                let toUpdate = {}
                                const value = e.replace('Order Id: ', '')
                                toUpdate = { metadata: { ...record.metadata, orderId: value } }

                                if (record.whmcsInvoiceId) {
                                    const value = e.replace('WHMCS: ', '')
                                    toUpdate = { whmcsInvoiceId: value }
                                }

                                try {
                                    await updateDoc(doc(getFirestore(), 'payments', record.id), toUpdate)
                                } catch (error) {
                                    console.log(error)
                                }
                            },
                        }}
                    >
                        {record?.whmcsInvoiceId
                            ? `WHMCS: ${record?.whmcsInvoiceId}`
                            : `Order Id: ${record?.metadata?.orderId || ''}`}
                    </Typography.Text>
                    <CopyableElementId id={record?.fid ?? record.id} idName="ID" />
                    <CheckIfRelating element={record} collection="payments" />
                    {record?.status === 'review_requested' && (
                        <Button
                            type="primary"
                            style={{ marginTop: '5px' }}
                            size="small"
                            onClick={() => {
                                dispatch(
                                    setData({
                                        item: 'payment',
                                        data: record,
                                    }),
                                )
                                dispatch(openModal('approveModalVisible'))
                            }}
                        >
                            Revisar
                        </Button>
                    )}
                </Space>
            )
        case 'from':
            return (
                <Row justify="center" style={{ margin: 'auto' }}>
                    <ElementFromTag record={record} recordType="payment" />
                </Row>
            )
        case 'timestamp':
            return (
                <Space direction="vertical" size={1}>
                    <Typography.Text className="p-base-regular">
                        {moment(record.timestamp).format('DD MMM YY HH:mm')}
                    </Typography.Text>
                </Space>
            )
        case 'succeededTimestamp':
            return (
                <Space direction="vertical" size={1}>
                    <Typography.Text className="p-base-regular">
                        {(record.succeededTimestamp || record.status_transitions) &&
                            moment(record.succeededTimestamp || record.status_transitions.paid_at * 1000).format(
                                'DD MMM YY HH:mm',
                            )}
                    </Typography.Text>
                </Space>
            )
        case 'automations':
            const isSucceeded = record.status === 'succeeded' || record.status === 'paid'
            const hasReceiptsOrInvoices = (record.receipts ?? []).length >= 1 || (record.invoices ?? []).length >= 1
            const hasAutomations = (record.automations ?? []).length >= 1
            if (!hasAutomations) return <></>

            return (
                <Row align="middle" justify="center" style={{ width: '100%' }}>
                    {hasAutomations && (
                        <Tooltip
                            title={
                                isSucceeded && !hasReceiptsOrInvoices
                                    ? 'Automatización fallida'
                                    : hasReceiptsOrInvoices
                                      ? 'Automatización exitosa'
                                      : 'Automatización pendiente'
                            }
                        >
                            <Robot
                                onClick={() => {
                                    //green #52C41A
                                    //red #F5222D
                                    //gray #BFBFBF
                                }}
                                size={20}
                                weight="regular"
                                color={
                                    isSucceeded && !hasReceiptsOrInvoices
                                        ? colors.red
                                        : hasAutomations && isSucceeded
                                          ? colors.green
                                          : colors.icons_muted
                                }
                                style={{ display: 'flex', padding: 0 }}
                            />
                        </Tooltip>
                    )}
                </Row>
            )
        case 'relations':
            return <RelationInvoice record={record} />

        case 'status':
            return <PaymentStatusTag shape="circle" record={record} />

        case 'suggestedActions':
            return (
                <div className="d-flex flex-columns">
                    <Button type="primary" size="small">
                        Revisar pago
                    </Button>
                </div>
            )
        case 'actions':
            return <PaymentsActions payment={{ ...record, hide: false }} />
    }
}

export const PendingPaymentsColumns = ({
    record,
    columnName,
    navigate,
}: {
    record: Payment | OptimizedPaymentDef
    columnName: TypePaymentsColumnName
    navigate?: Function
}) => {
    const dispatch = useDispatch()
    switch (columnName) {
        case 'id':
            return <span>{record.id ?? record?.fid}</span>
        case 'amount':
            var showTotal = getItemsAmounts(record.items).total
            return (
                <Row onClick={() => {}}>
                    <Typography.Text onClick={() => {}} className="smallparagraph descriptions">
                        {' '}
                        {returnCurrencyValue(showTotal)} {(record?.currency ?? '').toUpperCase()}
                    </Typography.Text>
                    {/* <Typography.Text className="smallparagraph descriptions">{returnCurrencyValue(record.amount / 100 ?? 0)} {record.currency.toLocaleUpperCase()}</Typography.Text> */}
                </Row>
            )
        case 'clientInfo':
            const showAddClient = !record.clientId
            const name =
                record.clientName ??
                record?.client?.name ??
                record?.client?.company ??
                record?.client?.legal_name ??
                record?.client?.legalName ??
                'Sin nombre'
            const nameTruncated = (name ?? '')?.length > 20 ? name?.substring(0, 20) + '...' : name
            return (
                <div className="d-flex flex-column">
                    <Row>
                        <Tooltip title={name}>
                            <Typography.Text
                                className="smallparagraphbold primary"
                                onClick={() => {
                                    dispatch(
                                        setData({
                                            item: 'payment',
                                            data: record,
                                        }),
                                    )
                                    dispatch(openModal('approveModalVisible'))
                                }}
                            >
                                {nameTruncated.toUpperCase()}
                            </Typography.Text>
                        </Tooltip>
                        {showAddClient && (
                            <Tooltip title="Asignar cliente">
                                <UserPlus size={18} style={{ marginLeft: '10px' }} className="icon clickable" />
                            </Tooltip>
                        )}
                        {!record?.livemode && (
                            <Tooltip title="Modo prueba">
                                <ShootingStar
                                    size={16}
                                    className="icon"
                                    style={{
                                        marginLeft: '5px',
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Row>
                    {record?.status === 'review_requested' && (
                        <Button
                            type="primary"
                            style={{ marginTop: '5px' }}
                            size="small"
                            onClick={() => {
                                dispatch(
                                    setData({
                                        item: 'payment',
                                        data: record,
                                    }),
                                )
                                dispatch(openModal('approveModalVisible'))
                            }}
                        >
                            Revisar
                        </Button>
                    )}
                </div>
            )
        case 'from':
            return (
                <Row style={{ margin: 'auto' }}>
                    <Tooltip title={`Pago creado desde ${readableElementSource(record?.from ?? '')}`}>
                        <Tag color="#D3D9FF">
                            <Typography.Text className="smallparagraphbold blue">
                                {readableElementSource(record.from ?? '')}
                            </Typography.Text>
                        </Tag>
                    </Tooltip>
                </Row>
            )
        case 'timestamp':
            return (
                <Space direction="vertical" size={1}>
                    <Typography.Text className="smallparagraph descriptions">
                        {moment(record.timestamp).format('DD MMM YY HH:mm')}
                    </Typography.Text>
                </Space>
            )
        case 'succeededTimestamp':
            return (
                <Space direction="vertical" size={1}>
                    <Typography.Text className="smallparagraph descriptions">
                        {(record.succeededTimestamp || record.status_transitions) &&
                            moment(record.succeededTimestamp || record.status_transitions.paid_at * 1000).format(
                                'DD MMM YY HH:mm',
                            )}
                    </Typography.Text>
                </Space>
            )
        case 'automations':
            const isSucceeded = record.status === 'succeeded' || record.status === 'paid'
            const hasReceiptsOrInvoices = (record.receipts ?? []).length >= 1 || (record.invoices ?? []).length >= 1
            const hasAutomations = (record.automations ?? []).length >= 1
            if (!hasAutomations) return <></>

            return (
                <Row align="middle">
                    {hasAutomations && (
                        <Tooltip
                            title={
                                isSucceeded && !hasReceiptsOrInvoices
                                    ? 'Automatización fallida'
                                    : hasReceiptsOrInvoices
                                      ? 'Automatización exitosa'
                                      : 'Automatización pendiente'
                            }
                        >
                            <Robot
                                onClick={() => {
                                    console.log('hasAutomations', hasAutomations)
                                    console.log('isSucceeded', isSucceeded)
                                    console.log('hasReceiptsOrInvoices', hasReceiptsOrInvoices)
                                    console.log('record', record)
                                }}
                                size={20}
                                weight="regular"
                                color={
                                    isSucceeded && !hasReceiptsOrInvoices
                                        ? '#F5222D'
                                        : hasAutomations && isSucceeded
                                          ? '#52C41A'
                                          : '#BFBFBF'
                                }
                                style={{ display: 'flex', padding: 0 }}
                            />
                        </Tooltip>
                    )}
                </Row>
            )
        case 'relations':
            return <RelationInvoice record={record} />

        case 'status':
            return <PaymentStatusTag shape="circle" record={record} />

        case 'suggestedActions':
            return (
                <div className="d-flex flex-columns">
                    <Button type="primary" size="small">
                        Revisar pago
                    </Button>
                </div>
            )
        case 'actions':
            return <PaymentsActions payment={{ ...record, hide: false }} />
    }
}
