import { useEffect, useState, useMemo } from 'react'
import { Typography, Row, Col, Space } from 'antd'
import PricingCard from './PricingCard'
import { collection, getDocs, getFirestore, query } from 'firebase/firestore'
import { Asterisk, ArrowLeft } from '@phosphor-icons/react'
import arrowPricing from '../../../assets/images/arrow_pricing.png'
import { PricingPlan } from '../../../interfaces/PricingPlanDef'
import { SearchParamInURL } from '../../functions/helpers'

const PLAN_ORDER = ['free', 'free-plus', 'essential', 'advanced', 'professional', 'professional-plus']

interface PricingCardDeckProps {
    finishSetup: () => void
}

export default function PricingCardDeck({ finishSetup }: PricingCardDeckProps) {
    const db = getFirestore()
    const [plans, setPlans] = useState<PricingPlan[]>([])
    const [displayedPlans, setDisplayedPlans] = useState<PricingPlan[]>([])
    const [showUpgradePlans, setShowUpgradePlans] = useState(false)
    const requestId = SearchParamInURL('request_id')

    const sortedPlans = useMemo(
        () => [...plans].sort((a, b) => PLAN_ORDER.indexOf(a.id) - PLAN_ORDER.indexOf(b.id)),
        [plans],
    )

    const freePlan = useMemo(() => sortedPlans.find((plan) => plan.id === 'free'), [sortedPlans])
    const freePlusPlan = useMemo(() => sortedPlans.find((plan) => plan.id === 'free-plus'), [sortedPlans])

    useEffect(() => {
        const getPlans = async () => {
            const q = query(collection(db, 'subscriptionPricing'))
            const subscriptionPlans = await getDocs(q)
            const fetchedPlans = subscriptionPlans.docs.map((doc) => doc.data()) as PricingPlan[]

            setPlans(fetchedPlans)
        }

        getPlans()
    }, [db])

    useEffect(() => {
        const updateDisplayedPlans = () => {
            if (!freePlan) return

            const filteredPlans = sortedPlans.filter((plan) => !['custom', 'free-plus'].includes(plan.id))
            setDisplayedPlans(filteredPlans)
        }

        updateDisplayedPlans()
    }, [sortedPlans, freePlan])

    const handleFreePlanSelection = () => {
        setShowUpgradePlans(true)
    }

    const handleBackToPlans = () => {
        setShowUpgradePlans(false)
    }

    return (
        <div className="pricing-card-deck">
            <Space direction="horizontal" size="small">
                {showUpgradePlans ? (
                    <ArrowLeft size={24} className="clickable" onClick={handleBackToPlans} />
                ) : (
                    <Asterisk size={24} />
                )}
                <Typography className="h4-bold">
                    {showUpgradePlans ? 'Obtén un UPGRADE en tu plan' : 'Tenemos el plan ideal para ti'}
                </Typography>
            </Space>
            {showUpgradePlans ? (
                <>
                    <Typography className="h6-regular">
                        Compara los planes y elige el que mejor se adapte a tus necesidades
                    </Typography>
                    <Row gutter={[24, 24]} justify="center" className="pricing-cards">
                        <Col xs={24} sm={24} md={12}>
                            <PricingCard plan={freePlan!} isRecommended={false} onSelect={finishSetup} />
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <PricingCard plan={freePlusPlan!} isRecommended={true} onSelect={finishSetup} />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <img src={arrowPricing} alt="" />
                    </Row>
                </>
            ) : (
                <>
                    <Typography className="h6-regular">Elige el plan que mejor se adapte a tus necesidades</Typography>
                    <Row
                        gutter={[16, 16]}
                        className="pricing-cards"
                        style={{ flexWrap: 'nowrap', minWidth: 'max-content' }}
                    >
                        {displayedPlans.map((plan, index) => (
                            <Col key={plan.id} style={{ minWidth: '250px', maxWidth: '300px' }}>
                                <PricingCard
                                    plan={plan}
                                    isRecommended={index === 2}
                                    onSelect={plan.id === 'free' ? handleFreePlanSelection : undefined}
                                    disabled={Boolean(plan.id === 'free' && requestId)}
                                />
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </div>
    )
}
