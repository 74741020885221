import { useState, useEffect } from 'react'
import { Layout, Row, Col, Typography, Button, Alert, Modal, Spin, message } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { usePostHog } from 'posthog-js/react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import PricingCardDeck from '../components/ComponentIndividual/PricingCardDeck'
import { getBillingData } from '../functions/Billing'

const Memberships = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const functions = getFunctions()
    const posthog = usePostHog()
    const [recurrence, setRecurrence] = useState<'monthly' | 'annual'>('monthly')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState('')
    const billingManager = httpsCallable(functions, 'internalbillingapp')
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const [searchParams] = useSearchParams()
    const customPriceId = searchParams.get('customPriceId')
    const rbca = searchParams.get('rbca')
    const code = searchParams.get('code')
    const { billing } = useSelector((state: any) => state.data)
    const { subscription } = billing ?? { subscription: null, plan: null }

    // CouponModal states
    const [openCoupon, setOpenCoupon] = useState(code !== null && code !== undefined && !customPriceId)
    const [coupon, setCoupon] = useState<any>(null)

    // ShowOnlyModal states
    const [openCustom, setOpenCustom] = useState(customPriceId !== null && customPriceId !== undefined)
    const [price, setPrice] = useState<any>(null)

    const requestCoupon = httpsCallable(functions, 'membershipsapp')

    useEffect(() => {
        if (!subscription && billingAccount?.customerId) {
            getBillingData({ team: billingAccount, user: null, dispatch })
        }

        if (code && !coupon && openCoupon) {
            requestCoupon({ action: 'coupondata', couponId: code })
                .then((r: any) => {
                    if (r.data?.error) {
                        setOpenCoupon(false)
                        return message.error(r.data.error)
                    }
                    setCoupon(r.data)
                })
                .catch((error) => {
                    console.error(error)
                })
        }

        if (customPriceId && !price && openCustom) {
            requestCoupon({ action: 'customprice', priceId: customPriceId })
                .then((r: any) => {
                    if (r.data?.error) {
                        setOpenCustom(false)
                        return message.error(r.data.error)
                    }
                    setPrice(r.data)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }, [
        subscription,
        billingAccount,
        dispatch,
        code,
        coupon,
        openCoupon,
        customPriceId,
        price,
        openCustom,
        requestCoupon,
    ])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleRecurrenceChange = (checked: boolean) => {
        setRecurrence(checked ? 'annual' : 'monthly')
    }

    const subscriptionButton = async (priceId: string) => {
        try {
            if (subscription?.status === 'active') {
                return navigate('/settings?subtab=billingAccount&open=settings_subscription')
            }
            if (!billingAccount?.id) {
                let red = '/signup?redirect=/memberships'
                if (customPriceId) {
                    red = `${red}&customPriceId=${customPriceId}`
                }
                if (code) {
                    red = `${red}&code=${code}`
                }
                return navigate(red)
            }
            setLoading(priceId)
            try {
                posthog.capture('subscription_started', {
                    priceId,
                    recurrence,
                })
            } catch (error) {}
            const r = await billingManager({
                action: 'subscribe',
                coupon: code,
                priceId,
                rbca,
                successUrl: 'https://app.gigstack.pro/settings?subtab=billingAccount&open=settings_subscription',
                cancelUrl: window.location.href,
                quantity: 1,
            })
            const data = r.data as any
            if (data?.error) {
                setLoading('')
                return message.error(data?.message ?? data.error)
            }
            setLoading('')
            if (data.session) {
                window.open(data.session?.url, '_blank')
            } else if (data.raw?.message) {
                try {
                    posthog.capture('subscription_error', {
                        priceId,
                        recurrence,
                        error: data.raw?.message,
                    })
                } catch (error) {}
                message.error(data.raw?.message)
            }
        } catch (error: any) {
            message.error(error?.message)
        }
    }

    const RenderCoupon = () => {
        if (!coupon) {
            return <Spin />
        }
        return (
            <Row>
                <Col xs={24}>
                    <Row justify="center">
                        <Typography className="mediumparagraph" style={{ margin: 0, padding: 0, textAlign: 'center' }}>
                            {coupon.name}
                        </Typography>
                    </Row>
                </Col>
                <Col xs={24}>
                    <ul>
                        <li>
                            <Typography className="smallparagraph" style={{ margin: 0, padding: 0 }}>
                                Duración:{' '}
                                {coupon?.duration === 'forever' ? 'Sin vencimiento' : coupon?.duration_in_months ?? 1}{' '}
                                {coupon?.duration === 'forever' ? '' : 'meses'}
                            </Typography>
                        </li>
                        <li>
                            <Typography className="smallparagraph" style={{ margin: 0, padding: 0 }}>
                                Descuento: {coupon.percent_off ? `${coupon.percent_off}%` : `${coupon.discount}`}
                            </Typography>
                        </li>
                    </ul>
                </Col>
                <Col xs={24}>
                    <Row>
                        <Button
                            block
                            type="primary"
                            onClick={() => {
                                posthog.capture('coupon_used', {
                                    couponId: code,
                                })
                                setOpenCoupon(false)
                            }}
                        >
                            Seleccionar un plan
                        </Button>
                    </Row>
                </Col>
            </Row>
        )
    }

    return (
        <Layout className="bg-dots bg-neutral-6" style={{ minHeight: '100vh' }}>
            <Modal open={openCoupon} onCancel={() => setOpenCoupon(false)} title={null} footer={null}>
                <Row justify="center">
                    <Typography>¡Recibiste un código de descuento!</Typography>
                </Row>
                <RenderCoupon />
            </Modal>
            <Modal open={openCustom} onCancel={() => setOpenCustom(false)} title={null} footer={null}>
                <Row justify="center">
                    <Typography>Plan Personalizado</Typography>
                </Row>
                {price ? (
                    <Row>
                        <Col xs={24}>
                            <Typography>{price.name}</Typography>
                        </Col>
                        <Col xs={24}>
                            <Button type="primary" onClick={() => subscriptionButton(customPriceId ?? '')}>
                                Contratar plan
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <Spin />
                )}
            </Modal>

            <Row justify="center">
                <Col xs={23} lg={19} style={{ padding: '10px 2px' }}>
                    <Row justify="end" style={{ marginBottom: '1rem' }}>
                        <Col>
                            <Button type="link" onClick={() => navigate('/')} className="neutral-1">
                                Mi cuenta
                            </Button>
                        </Col>
                    </Row>
                    <PricingCardDeck finishSetup={() => navigate('/gettingStarted')} />
                    {subscription?.status === 'active' && (
                        <Row justify="center" style={{ marginTop: '2rem' }}>
                            <Col xs={24} lg={18}>
                                <Alert
                                    type="info"
                                    message="Actualmente ya tienes un plan contratado, si deseas cambiarlo por favor contacta con nuestro equipo"
                                />
                            </Col>
                        </Row>
                    )}
                </Col>
                {/* <Row justify="center" style={{ marginBottom: '2rem' }}>
                    <Col>
                        <Typography.Text>Mensual</Typography.Text>
                        <Switch
                            checked={recurrence === 'annual'}
                            onChange={handleRecurrenceChange}
                            style={{ margin: '0 8px' }}
                        />
                        <Typography.Text>Anual</Typography.Text>
                    </Col>
                </Row> */}
            </Row>
        </Layout>
    )
}

export default Memberships
