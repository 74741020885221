import React, { useEffect, useState } from 'react'
import { Form, FormInstance, Row, Col, Typography, Button, message, Tag, Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { CheckCircle } from '@phosphor-icons/react'
import { returnCurrencyValue } from '../../functions/helpers'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { usePermissions } from '../../customHooks/usePermissions'
import { getBillingData } from '../../functions/Billing'

interface SuscriptionFormProps {
    incomingForm?: FormInstance
}

const SuscriptionForm = ({ incomingForm }: SuscriptionFormProps) => {
    const dispatch = useDispatch()
    const { billing } = useSelector((state: any) => state.data)
    const { subscription } = billing ?? {
        subscription: null,
        plan: null,
    }
    const navigate = useNavigate()

    const [openingSub] = useState(false)
    const { isBillingAccountAdmin } = usePermissions()
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    // Your component logic here
    const [form] = Form.useForm()
    const usingForm = incomingForm ?? form
    const functions = getFunctions()
    const billingManager = httpsCallable(functions, 'internalbillingapp')

    useEffect(() => {
        if (!subscription && billingAccount?.customerId) {
            getBillingData({ team: billingAccount, user: null, dispatch })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription])

    const activeSubscription = subscription || billingAccount?.plan
    const current_period_end = billingAccount?.current_period_end || subscription?.current_period_end

    const PlanHeader = () => {
        return (
            <Row>
                <Row>
                    <Typography.Text className="mediumheader" style={{ marginLeft: '0px' }}>
                        Plan{' '}
                        {activeSubscription ? activeSubscription?.plan?.nickname || billingAccount?.plan?.name : ''}
                    </Typography.Text>
                </Row>
                <Tag color={activeSubscription ? 'green' : 'red'} style={{ marginLeft: '10px' }}>
                    {activeSubscription ? 'Activo' : 'Inactivo'}
                </Tag>
            </Row>
        )
    }

    const NoSubscription = () => {
        return (
            <Row justify="center">
                <Col xs={24}>
                    <Divider />
                </Col>
                <Col xs={24} md={12} lg={12}>
                    <div className="d-flex flex-column">
                        <Typography.Text className="smallheader" style={{ marginBottom: '10px' }}>
                            Actualiza tu cuenta
                        </Typography.Text>
                        <Row style={{ marginTop: '5px' }}>
                            <CheckCircle size={19} weight="regular" className="primary" />
                            <Typography.Text className="mediumparagraph descriptions" style={{ marginLeft: '10px' }}>
                                Acceso a API
                            </Typography.Text>
                        </Row>
                        <Row style={{ marginTop: '5px' }}>
                            <CheckCircle size={19} weight="regular" className="primary" />
                            <Typography.Text className="mediumparagraph descriptions" style={{ marginLeft: '10px' }}>
                                1,000 créditos al mes
                            </Typography.Text>
                        </Row>
                        <Row style={{ marginTop: '5px' }}>
                            <CheckCircle size={19} weight="regular" className="primary" />
                            <Typography.Text className="mediumparagraph descriptions" style={{ marginLeft: '10px' }}>
                                Pago por eventos
                            </Typography.Text>
                        </Row>
                        <Row style={{ marginTop: '5px' }}>
                            <CheckCircle size={19} weight="regular" className="primary" />
                            <Typography.Text className="mediumparagraph descriptions" style={{ marginLeft: '10px' }}>
                                Soporte prioritario
                            </Typography.Text>
                        </Row>
                        <Row style={{ marginTop: '5px' }}>
                            <CheckCircle size={19} weight="regular" className="primary" />
                            <Typography.Text className="mediumparagraph descriptions" style={{ marginLeft: '10px' }}>
                                Clientes ilimitados
                            </Typography.Text>
                        </Row>
                    </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                    <div
                        style={{
                            border: '1px solid #E5E5E5',
                            padding: '10px 15px',
                            borderRadius: '10px',
                        }}
                        className="d-flex flex-column"
                    >
                        <Row justify="center">
                            <Typography.Text className="smallheader">Tenemos un plan para ti</Typography.Text>
                        </Row>
                        <Row justify="center" style={{ marginTop: '15px' }}>
                            <Button
                                type="primary"
                                loading={openingSub}
                                onClick={async () => {
                                    try {
                                        navigate('/memberships')
                                    } catch (error: any) {
                                        message.error(error?.message)
                                    }
                                }}
                            >
                                Suscribirme
                            </Button>
                        </Row>
                        {/* TODO: ENVIAR A PAGINA DE PLANES  */}
                        <Row justify="center" style={{ marginTop: '15px' }}>
                            <Typography.Text
                                onClick={() => {
                                    navigate('/memberships')
                                }}
                                className="smallparagraph descriptions clickable"
                            >
                                ¿Buscas algo más pequeño?
                            </Typography.Text>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }

    const ActiveSubscription = () => {
        const planAmount = subscription?.plan?.amount || billingAccount?.plan?.price?.baseAmount
        const planCurrency = subscription?.plan?.currency || billingAccount?.plan?.price?.currency
        const planInterval = subscription?.plan?.interval || billingAccount?.plan?.interval
        const additionalDocumentPrice = billingAccount?.plan?.price?.additionalDocumentPrice

        const current_period_end = billingAccount?.current_period_end || subscription?.current_period_end

        return (
            <div className="d-flex flex-column">
                <Row style={{ marginTop: '20px' }}>
                    <Col xs={24} lg={12}>
                        <Typography.Text className="smallheader">Monto de la suscripción</Typography.Text>
                    </Col>
                    <Col xs={24} lg={12}>
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallparagraph">
                                {activeSubscription
                                    ? `${returnCurrencyValue(planAmount / 100)} ${planCurrency} / ${planInterval === 'year' ? 'año' : 'mes'}`
                                    : 'Sin plan activo'}
                            </Typography.Text>

                            <Typography.Text className="smallparagraph descriptions">
                                {activeSubscription
                                    ? `Siguiente cobro ${moment(current_period_end * 1000).format('DD MMM YYYY HH:mm')}`
                                    : 'Contacta a soporte para retomar tu plan'}
                            </Typography.Text>
                        </div>
                    </Col>
                </Row>
                {additionalDocumentPrice !== undefined && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24} lg={12}>
                            <Typography.Text className="smallheader">Costo por documento adicional</Typography.Text>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className="d-flex flex-column">
                                <Typography.Text className="smallparagraph">
                                    {`${returnCurrencyValue(additionalDocumentPrice / 100)} ${planCurrency} / documento`}
                                </Typography.Text>
                                <Typography.Text className="smallparagraph descriptions">
                                    Costo por cada documento procesado más allá del límite del plan
                                </Typography.Text>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        )
    }

    return (
        <Form form={usingForm}>
            <Row>
                <Col xs={24}>
                    <PlanHeader />
                </Col>
                <Col xs={24}>{!activeSubscription ? <></> : <ActiveSubscription />}</Col>
                <Col xs={24}>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24} lg={12}>
                            <Typography.Text className="smallheader">Eventos disponibles</Typography.Text>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className="d-flex flex-column">
                                <Typography.Text className="smallparagraph">
                                    {billingAccount?.plan?.features?.includedDocuments ?? billingAccount?.credits}{' '}
                                    eventos disponibles.
                                </Typography.Text>
                                <Typography.Text className="smallparagraph descriptions">
                                    Siguiente renovación {moment(current_period_end * 1000).format('DD MMM YYYY HH:mm')}
                                </Typography.Text>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24} lg={12}>
                            <Typography.Text className="smallheader">Último evento utilizado</Typography.Text>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className="d-flex flex-column">
                                <Typography.Text className="smallparagraph">
                                    {!billingAccount?.lastUsed
                                        ? ''
                                        : typeof billingAccount?.lastUsed !== 'number'
                                          ? moment(billingAccount?.lastUsed).format('DD MMMM YYYY HH:mm')
                                          : moment(billingAccount?.lastUsed).format('DD MMM YYYY HH:mm')}
                                </Typography.Text>
                            </div>
                        </Col>
                    </Row>
                </Col>

                {activeSubscription && (
                    <Col xs={24} style={{ marginTop: '25px' }}>
                        <Row justify="end">
                            <Button
                                disabled={!isBillingAccountAdmin}
                                type="primary"
                                htmlType="button"
                                onClick={async () => {
                                    if (!isBillingAccountAdmin) return
                                    const r = await billingManager({
                                        action: 'session',
                                    })
                                    const data = r.data as any
                                    if (data?.session) {
                                        window.open(data.session, '_blank')
                                    }
                                }}
                            >
                                {isBillingAccountAdmin
                                    ? 'Actualizar suscripción y/o tarjeta'
                                    : 'Pide a un administrador realizar cambios'}
                            </Button>
                        </Row>
                    </Col>
                )}
                {!activeSubscription && (
                    <Col xs={24} style={{ marginTop: '25px' }}>
                        <NoSubscription />
                    </Col>
                )}
            </Row>
        </Form>
    )
}

export default SuscriptionForm
