import './App.css'
import './css/Gigstack.css'
import './css/antd-overrides.css'
import './css/Typography.css'
import './css/Colors.css'

import {
    AnalyticsProvider,
    AppCheckProvider,
    AuthProvider,
    FirestoreProvider,
    StorageProvider,
    useFirebaseApp,
    useInitPerformance,
} from 'reactfire'
import { connectFirestoreEmulator, getFirestore, initializeFirestore } from 'firebase/firestore'
import { ConfigProvider, message } from 'antd'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import moment from 'moment'
import MainRoutes from './app/routes/MainRoutes'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'moment/locale/es-mx'
import locale from 'antd/locale/es_ES'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useEffect } from 'react'
import { antdTheme } from './css/antd-theme'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
const APP_CHECK_TOKEN = '6LcN4cscAAAAAIRntjbXxgZyqzXEO5x2xBKDBezv'

function App() {
    dayjs.locale('es')
    moment.locale('es-MX')

    const app = useFirebaseApp()
    initializeFirestore(app, { ignoreUndefinedProperties: true })

    const firestoreInstance = getFirestore(app)
    const auth = getAuth(app)
    const storage = getStorage(app)
    const analyticssdk = getAnalytics(app)
    const functions = getFunctions(app)

    if (process.env.NODE_ENV === 'development' || window.location.host.includes('localhost')) {
        connectAuthEmulator(auth, 'http://localhost:9099')

        /* @ts-ignore */
        if (!firestoreInstance._settingsFrozen) {
            connectFirestoreEmulator(firestoreInstance, 'localhost', 8080)
        }

        connectStorageEmulator(storage, 'localhost', 9199)
        connectFunctionsEmulator(functions, 'localhost', 5001)
    }

    useInitPerformance(async (app) => {
        const { getPerformance } = await import('firebase/performance')
        return getPerformance(app)
    })

    // const { data: remoteConfigInstance } = useInitRemoteConfig(async (firebaseApp) => {
    //   const remoteConfig = getRemoteConfig(firebaseApp);
    //   remoteConfig.settings = {
    //     minimumFetchIntervalMillis: 10000,
    //     fetchTimeoutMillis: 10000,
    //   };
    //   await fetchAndActivate(remoteConfig);
    //   return remoteConfig;
    // });

    const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
        isTokenAutoRefreshEnabled: false,
    })

    useEffect(() => {
        if (process.env.NODE_ENV === 'development' || window.location.host.includes('localhost')) {
            message.info(`Corriendo en ${process.env.NODE_ENV}`)
            // return
        }

        if (process.env.NODE_ENV === 'production') {
            posthog.init('phc_rd0aAtXdBAomlJIQREuxMm6XZjVFrVNkfgCk8lPYe7S', {
                api_host: 'https://app.posthog.com',
                opt_in_site_apps: true,
            })
        }
    }, [])

    /*APP*/

    return (
        <AuthProvider sdk={auth}>
            <FirestoreProvider sdk={firestoreInstance}>
                <AppCheckProvider sdk={appCheck}>
                    <StorageProvider sdk={storage}>
                        {/* <RemoteConfigProvider sdk={remoteConfigInstance}> */}
                        <AnalyticsProvider sdk={analyticssdk}>
                            <ConfigProvider theme={antdTheme} locale={locale}>
                                <PostHogProvider client={posthog}>
                                    <MainRoutes />
                                </PostHogProvider>
                            </ConfigProvider>
                        </AnalyticsProvider>
                        {/* </RemoteConfigProvider> */}
                    </StorageProvider>
                </AppCheckProvider>
            </FirestoreProvider>
        </AuthProvider>
    )
}

export default App
