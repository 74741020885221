import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAuth } from 'reactfire'
import { Button, Col, Form, FormProps, message, Row, Typography } from 'antd'
import { SelectInput } from '../components/Forms/Inputs'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { SearchParamInURL } from '../functions/helpers'
import { getFirestore, doc, getDoc } from 'firebase/firestore'
import Step1Gif from '../../assets/images/finishSetup1.gif'
import { useNavigate } from 'react-router-dom'
import { TeamDef } from '../../interfaces/teamDef'

export const OAuthView = () => {
    const [name, setName] = useState('')
    const [selectOptions, setSelectOptions] = useState<Record<string, unknown>[]>([])
    const [loading, setLoading] = useState(false)
    const [from, setFrom] = useState('')

    const { user } = useSelector((state: any) => state.user)
    const { team } = useSelector((state: any) => state.team)
    const { teams } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const requestId = SearchParamInURL('request_id')
    const state = SearchParamInURL('state')
    const db = getFirestore()
    const auth = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            // const teamsQuery = query(
            //     collection(db, 'teams'),
            //     where('billingAccount', '==', user?.lastBillingAccountViewed ?? billingAccount?.id),
            //     orderBy('timestamp', 'desc'),
            // )

            // // const teamsSnapshot = await getDocs(teamsQuery)
            setSelectOptions(
                teams
                    // .filter((t) => !t?.stripe?.completed) momentaneamente se quita el filtro para habilitar pruebas
                    .map((doc: TeamDef) => ({
                        label: doc?.brand?.alias,
                        logo: doc?.brand?.logo,
                        emoji: '🚀',
                        value: doc?.id,
                    })),
            )

            const oAuthRequestDoc = await getDoc(doc(db, 'oauthRequest', requestId!))
            if (oAuthRequestDoc.exists()) {
                setFrom(oAuthRequestDoc.data()?.from)
            } else {
                navigate('/home')
            }

            setName(user?.firstName || '')
        }

        fetchData()
    }, [db, team.id, billingAccount?.id, requestId, user?.firstName, navigate, user?.lastBillingAccountViewed, teams])

    const onFinish: FormProps['onFinish'] = async (body) => {
        setLoading(true)
        try {
            const { teams } = body
            const approveResponse = await SignedInternalAPIRequest(
                { team: teams, requestId, state },
                'oauth2/approve',
                auth.currentUser,
            )

            const finalRedirectUrl = new URL(approveResponse.redirectUri)
            finalRedirectUrl.searchParams.append('team', teams)
            if (state) {
                finalRedirectUrl.searchParams.append('state', state)
            }

            window.location.href = finalRedirectUrl.toString()
        } catch (error) {
            message.error('Error durante la aprobación. Por favor, inténtalo de nuevo.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="bg-dots bg-neutral-6">
            <Row justify="center" align="middle">
                <Col
                    xs={24}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        overflow: 'hidden',
                    }}
                >
                    <Row>
                        <Col xs={24} lg={12} style={{ padding: '30px' }} className="shadow-lg rounded-lg bg-white">
                            <div className="d-flex flex-column" style={{ marginTop: '20px', marginBottom: '40px' }}>
                                <Typography.Text className="h5-bold neutral-1">Selecciona una cuenta a conectar</Typography.Text>
                                <div style={{ marginTop: '20px' }}>
                                    <Form layout="vertical" onFinish={onFinish}>
                                        <SelectInput
                                            name="teams"
                                            label="Elige la cuenta"
                                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                                            options={selectOptions}
                                            defaultValue={selectOptions[0]?.value}
                                            optionRender={(option: any) => (
                                                <div className="d-flex align-items-center">
                                                    {option?.data?.logo && (
                                                        <img
                                                            src={option?.data?.logo}
                                                            alt={option?.data?.label}
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginRight: '10px',
                                                            }}
                                                        />
                                                    )}
                                                    <Typography.Text>{option.label}</Typography.Text>
                                                </div>
                                            )}
                                        />

                                        <Button className="btn-primary w-100" htmlType="submit" loading={loading}>
                                            Conectar
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </Col>

                        <Col xs={0} lg={12} style={{ overflow: 'hidden' }}>
                            <div style={{ height: '100%', padding: '0px 20px' }}>
                                <div style={{ padding: '30px', borderRadius: '10px' }} className="d-flex flex-column">
                                    <img style={{ width: '110px' }} src={Step1Gif} alt="Information setup animation" />
                                    <Typography.Text
                                        className="h4-bold neutral-1"
                                        style={{ padding: 0, marginTop: '40px' }}
                                    >
                                        Hola {name}
                                    </Typography.Text>
                                    <Typography.Text
                                        className="h6-regular neutral-2 fade-in-animation"
                                        style={{ marginTop: '15px' }}
                                    >
                                        Estamos emocionados de tenerte en gigstack. Para comenzar, selecciona el equipo
                                        que deseas conectar con {from}.{' '}
                                    </Typography.Text>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
