import { Button, Col, Divider, Form, Row, Typography, message } from 'antd'
import google from '../../assets/images/google.png'
import { TextInput, PasswordInput } from '../components/Forms/Inputs'
import { CaretDoubleRight } from '@phosphor-icons/react'
import { useAnalytics, useAuth } from 'reactfire'
import { useEffect, useState } from 'react'
import { ReadableFirebaseErrorMessage, SearchParamInURL } from '../functions/helpers'
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { logEvent } from 'firebase/analytics'
import ForgotPasswordModal from '../modals/ForgotPasswordModal'
import { useNavigate } from 'react-router-dom'
import { usePostHog } from 'posthog-js/react'

const LoginView: React.FC = () => {
    const posthog = usePostHog()
    const navigate = useNavigate()
    const auth = useAuth()
    const analytics = useAnalytics()
    const [loading, setLoading] = useState<string | null>(null)
    let redirect = SearchParamInURL('redirect')
    const [form] = Form.useForm()
    const [showModal, setShowModal] = useState(false)
    const MakeGoogleSignIn = async () => {
        setLoading('google')
        posthog?.capture('signed_in_with_google')
        setTimeout(() => {
            const provider = new GoogleAuthProvider()

            provider.addScope('profile')
            provider.addScope('email')
            logEvent(analytics, 'auth', { type: 'google' })
            signInWithPopup(auth, provider)
                .then(async (result) => {
                    const user = result.user

                    if (redirect) {
                        window.location.replace(redirect)
                    }
                    return user
                })
                .catch((error) => {
                    setLoading(null)
                    return message.error(ReadableFirebaseErrorMessage(error))
                })
        }, 300)
    }

    const searchRedirect = () => {
        const token = SearchParamInURL('token')
        if (token) {
            redirect = `${window.location.origin}/invites?token=${token}`
        }
    }

    const decodeBase64 = (token: string) => {
        const decodedToken = atob(token)
        const email = decodedToken.split('email=')[1].split('&password=')[0]
        const password = decodedToken.split('password=')[1].split('email=')[0]

        form.setFieldsValue({
            email,
            password,
        })
        posthog?.capture('signed_in_with_params')
        form.submit()
    }

    useEffect(() => {
        searchRedirect()
        const accessToken = SearchParamInURL('accessToken')
        if (accessToken) {
            decodeBase64(accessToken)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const LoginAction = async (v: any) => {
        if (!v.email || !v.password) {
            return
        }
        try {
            setLoading('email')
            try {
                posthog?.capture('signed_in_with_email')
            } catch (error) {}
            await signInWithEmailAndPassword(auth, v.email, v.password)
            if (redirect) {
                let correctRed = `${redirect}?`
                if (SearchParamInURL('code')) {
                    correctRed = correctRed + `&code=${SearchParamInURL('code')}`
                }
                if (SearchParamInURL('customPriceId')) {
                    correctRed = correctRed + `&customPriceId=${SearchParamInURL('customPriceId')}`
                }
                return window.location.replace(correctRed)
            } else {
                // return window.location.replace("/")
                const route = window.location.pathname.split('/')[1]
                if (route !== 'oauth') return navigate('/')
            }
        } catch (error) {
            setLoading(null)
            searchRedirect()
            message.error(ReadableFirebaseErrorMessage(error))
        }
    }

    return (
        <div
            style={{
                position: 'relative',
                minHeight: '100vh',
                background: 'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
            }}
        >
            <Row justify="center" align="middle">
                {showModal && <ForgotPasswordModal open={showModal} close={() => setShowModal(false)} />}
                {/* <Row justify="center">
                <Typography.Text className='russoOne primary' style={{
                    fontSize: '20px',
                    margin: 0, padding: 0,
                    marginTop: '10px'
                }}>gigstack pro</Typography.Text>
            </Row> */}
                <Col
                    xs={23}
                    lg={12}
                    xl={10}
                    style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '10px',
                        alignItems: 'center',
                        marginTop: '10px',
                    }}
                    className="d-flex flex-column"
                >
                    {/* <Typography.Text className='russoOne primary' style={{
                    fontSize: '20px',
                    margin: 0, padding: 0,
                    marginBottom: '10px',
                }}>gigstack pro</Typography.Text> */}
                    <Typography.Text className="mediumparagraphbold " style={{}}>
                        Inicia de Sesión
                    </Typography.Text>
                    <Typography.Text
                        className="smallparagraph descriptions"
                        style={{
                            marginBottom: '20px',
                        }}
                    >
                        Elige un método para iniciar sesión
                    </Typography.Text>
                    <Button
                        className="inter"
                        style={{
                            borderColor: window.innerWidth < 801 ? `white` : '#D8DAE5',
                            display: 'flex',
                            width: '100%',
                            padding: '8px 16px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: 'auto',
                            marginTop: window.innerWidth < 801 ? '20px' : '',
                        }}
                        onClick={MakeGoogleSignIn}
                    >
                        <img
                            src={google}
                            alt="Google Logo"
                            style={{
                                width: '14px',
                                height: '14px',
                                marginRight: '8px',
                            }}
                        />
                        {`Inicia con Google`}
                    </Button>
                    <Divider>
                        <Typography.Text className="smallparagraph descriptions">continúa con email</Typography.Text>
                    </Divider>
                    <Form form={form} onFinish={LoginAction} layout="vertical">
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: 'Ingresa con un correo válido',
                                },
                            ]}
                        >
                            <TextInput name="email" label={'Correo electrónico'} placeholder={'tunombre@empresa.com'} />
                        </Form.Item>

                        <Row justify="space-between" style={{ marginBottom: '5px' }}>
                            <label>Contraseña</label>
                            <Typography.Text
                                className="inter"
                                style={{
                                    cursor: 'pointer',
                                    color: '#8666FF',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    marginLeft: '10px',
                                }}
                                onClick={() => {
                                    setShowModal(true)
                                }}
                            >
                                ¿Olvidaste tu contraseña?
                            </Typography.Text>
                        </Row>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingresa tu contraseña',
                                },
                            ]}
                        >
                            <PasswordInput name="password" placeholder={'**********'} />
                        </Form.Item>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                type="primary"
                                style={{
                                    display: 'flex',
                                    width: window.innerWidth < 801 ? '150px' : '430px',
                                    padding: '8px 16px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                    margin: 'auto',
                                    // fontWeight: 600,
                                }}
                                htmlType="submit"
                                loading={loading === 'email'}
                            >
                                Iniciar Sesión
                                <CaretDoubleRight weight="regular" style={{}} />
                            </Button>
                            <Button
                                type="dashed"
                                style={{
                                    color: 'black',
                                    display: 'flex',
                                    width: window.innerWidth < 801 ? '150px' : '430px',
                                    padding: '8px 16px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                    fontWeight: 500,
                                    margin: 'auto',
                                    marginTop: '10px',
                                }}
                                onClick={() => {
                                    const paramsOauth = []
                                    const token = SearchParamInURL('token')
                                    const requestId = SearchParamInURL('request_id')
                                    const state = SearchParamInURL('state')

                                    searchRedirect()

                                    if (requestId) paramsOauth.push(`request_id=${requestId}`)
                                    if (state) paramsOauth.push(`state=${state}`)
                                    const queryString = paramsOauth.length > 0 ? `?${paramsOauth.join('&')}` : ''

                                    window.location.href = window.location.href.includes('/oauth')
                                        ? `/oauth/finishSetup${queryString} `
                                        : `/register${redirect && token ? `?redirect=${redirect}` : redirect ? `?redirect=${window.location.href.split('redirect=')[1]}` : ''}`
                                }}
                            >
                                Crear una cuenta
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default LoginView
