import moment from 'moment'
import { AddressDef, addressSet } from './clientDef'
import { MembershipPlan } from './PricingPlanDef'

export interface BillingAccountDef {
    legal_name: string
    legalName: string
    contactEmails: [string]
    address?: AddressDef | null
    rfc: string | null
    currency: string
    customerId: string | null
    // tax_system: TaxSystem,
    v: string
    timestamp: number | null
    supportPhone: string | null
    seats: number
    created: number | null
    credits: number
    id: string
    automations: boolean | null | number
    teamMembers: number | null
    paidTeamMembers: number | null
    admins: [string]
    teams: [string]
    owner: string
    masterAdmin: string
    blocked: boolean
    planCredits: number
    payAsYouGo: boolean
    lastUsed?: any
    tax_system?: string
    pendingTeamConfiguration?: string
    pendingTeamConfigurations?: number
    use?: string
    plan?: MembershipPlan
}

export const billingAccountDefSet = (object: any) => {
    const billingAccount: BillingAccountDef = {
        legal_name: object['legalName'] ?? object['legal_name'] ?? null,
        legalName: object['legalName'] ?? object['legal_name'] ?? null,
        contactEmails: object['contactEmails'] ?? [],
        address: addressSet(object['address'] ?? {}),
        rfc: object['rfc'] ?? null,
        currency: object['currency'] ?? 'MXN',
        customerId: object['customerId'] ?? null,
        // tax_system: object['tax_system'] ?? null,
        v: object['v'] ?? '3',
        timestamp: object['timestamp'] ?? moment().valueOf(),
        supportPhone: object['supportPhone'] ?? null,
        seats: object['seats'] ?? null,
        created: object['created'] ?? moment().valueOf(),
        credits: object['credits'] ?? 3,
        id: object['id'] ?? '',
        automations: object['automations'] ?? null,
        teamMembers: object['teamMembers'],
        paidTeamMembers: object['paidTeamMembers'] ?? [],
        admins: object['admins'] ?? [],
        teams: object['teams'] ?? [],
        owner: object['owner'] ?? object['uid'] ?? '',
        masterAdmin: object['masterAdmin'] ?? object['uid'] ?? '',
        blocked: object['blocked'] ?? null,
        planCredits: object['metadata']?.credits ?? 3,
        payAsYouGo: object['payAsYouGo'] ?? false,
        lastUsed: object['lastUsed'] ?? null,
        tax_system: object['tax_system'] ?? null,
        pendingTeamConfiguration: object['pendingTeamConfiguration'] ?? null,
        pendingTeamConfigurations: object['pendingTeamConfigurations'] ?? null,
        use: object['use'] ?? null,
        plan: object['plan'] ?? null,
    }
    return billingAccount
}
